import gsap from 'gsap';

let currentWindowWidth = window.innerWidth;

function setUpResize() {
  // Setup a timer
  let timeout;
  // Listen for resize events
  window.addEventListener('resize', function() {
    // If there's a timer, cancel it
    if (timeout) {
      window.cancelAnimationFrame(timeout);
    }
      // Setup the new requestAnimationFrame()
    timeout = window.requestAnimationFrame(function() {
      // Run our resize functions
      if (window.innerWidth !== currentWindowWidth) {
        sidebarMod.check();
        currentWindowWidth = window.innerWidth;
      }
    });
  }, false);
}

function check() {
  const sidebar = document.getElementById('sidebar');
  const contentContainer = document.getElementById('content-container');

  if (!sidebar || !contentContainer) {
    return;
  }

  const sidebarHeight = sidebar.offsetHeight;
  const contentHeight = contentContainer.offsetHeight;

  const totalVerticalPadding = Array.from(contentContainer.children).reduce((accumulator, element) => {
    return accumulator + gsap.getProperty(element, 'paddingTop') + gsap.getProperty(element, 'paddingBottom');
  }, 0);

  const targetHeight = sidebarHeight + totalVerticalPadding;

  if (contentHeight < targetHeight) {
    gsap.set(contentContainer, {minHeight: targetHeight});
  } else {
    gsap.set(contentContainer, {minHeight: 'none'});
  }
}

const sidebarMod = {
  setUpResize: setUpResize,
  check: check
}

sidebarMod.setUpResize();

export { sidebarMod };