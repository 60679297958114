import gsap from 'gsap';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

function handleLink(link) {
  const href = link.getAttribute('href');
  const target = document.querySelector(href);
  if (!target) return;
  
  gsap.to(window, {
    duration: 0.5,
    scrollTo: target,
  })
}

export default function() {
  document.addEventListener('click', function(e) {
    const link = e.target.closest('.js-home-anchor');
    if (link) {
      e.preventDefault();
      handleLink(link);
    }
  });
}