import page from './modules/page';
import dropdownModule from './modules/dropdown';
import search from './modules/search';
import mobile from './modules/mobile';
import filter from './modules/filter';
import header from './modules/header';
import video from './modules/video';
import carousel from './modules/carousel';
// import heroCarousel from './modules/hero-carousel';
import scroll from './modules/scroll';
import { sidebarMod } from './modules/sidebar';

page();
dropdownModule.init();
mobile.init();
filter();
header();
video();
carousel();
search.init();
scroll();

// if tab key is used, add class to show outline styles
function handleFirstTab(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', handleFirstTab);
  }
}

window.addEventListener('keydown', handleFirstTab);

window.addEventListener('load', (e) => { 
  // heroCarousel();
  sidebarMod.check();
  if (window.location.hash) {
    const selector = window.location.hash
    const matching = document.querySelector(selector)

    if (matching) {
      gsap.to(window, {
        duration: 0.5,
        scrollTo: {
          y: selector,
          x: 0
        }
      })
    }
  }
})