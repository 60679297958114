import Glide from '@glidejs/glide';

export default function() {
  const el = document.getElementById('hero-carousel');
  if (!el) {
    return;
  }

  const carousel = new Glide(el, {
    type: 'carousel',
    dragThreshold: false,
    autoplay: 5000,
    animationDuration: 1000,
  });

  carousel.mount();
}
