import barba from '@barba/core';
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
const activeIntroClass = 'filter-intro-is-active';
const newElAnimateClass = 'js-to-animate';

function checkExpertViews(url) {
  const el = document.getElementById('expert-views');
  if (el) {
    if (url.includes('expert-views')) {
      gsap.set(el, {display: 'block'});
    } else {
      gsap.set(el, {display: 'none'});
    }
  }
}

function requestError(trigger, action, url, response) {
  // Barba should automatically redirect if it experiences a timeout but it doesn't seem to.
  // Also the url param seems to always be undefined.
  // We can use the trigger param instead (which seems to be url) and use barba.force to redirect to that url with a full page load.
  // console.log(trigger);
  // console.log(action);
  // console.log(url);
  // console.log(response);
  if (!url) {
    barba.force(trigger);
  }
}

function ajax(options) {
  const { url, append = false } = options;
  const body = document.body;
  // Add css class - temporarily disable further clicks on filters and cards
  body.classList.add('loading-ajax-cards');

  barba.request(url, 5000, requestError).then((res) => {
    // Parse the returned HTML
    const parser = new DOMParser();
    const resHTML = parser.parseFromString(res, "text/html");
    const newContainer = resHTML.getElementById('ajax-cards');
    // If we're appending rather than replacing, add class to new cards in so we only animate those
    const newAnimateEls = newContainer.querySelectorAll('.js-ajax-animate');
    newAnimateEls.forEach(card => card.classList.add(newElAnimateClass));
    // Get innerHTML
    const newContent = newContainer.innerHTML;
    const ajaxContainer = document.getElementById('ajax-cards');
    // Prevent height 'jump' when swapping content
    const height = ajaxContainer.offsetHeight;
    ajaxContainer.style.height = `${height}px`;

    if (append) {
      const prevLoadMore = document.getElementById('load-more-container');
      if (prevLoadMore) {
        prevLoadMore.remove();  
      }
      ajaxContainer.insertAdjacentHTML('beforeend', newContent);
    } else {
      ajaxContainer.innerHTML = newContent;
      barba.history.add(url, 'barba');  
    }

    ajaxContainer.style.height = `auto`;

    const els = document.querySelectorAll(`.${newElAnimateClass}`);
    gsap.from(els, {
      opacity: 0,
      y: 10,
      duration: 0.5,
      delay: 0.2,
      stagger: 0.1,
    });
    // Remove newCardClass so you don't animate the same ones again if you load more
    els.forEach(el => el.classList.remove(newElAnimateClass));
    // Show expert views section if present
    checkExpertViews(url);
  }).catch((err) => {
    console.log(err);
  }).finally(() => {
    body.classList.remove('loading-ajax-cards');
  });
}

function updateFilters(filter) {
  const subChecked = Array.from(document.querySelectorAll('.js-filter[data-level="2"]:checked'));
  const checkedParentRadio = document.querySelector('.js-filter[data-level="1"]:checked'); 

  if (filter.dataset.level === "1") {
    const introSelector = checkedParentRadio.dataset.intro;
    const introContainer = document.getElementById(introSelector);
    const activeIntro = document.querySelector(`.${activeIntroClass}`);
    
    if (activeIntro) {
      gsap.timeline().to(activeIntro, {
        height: 0,
        duration: 0.2,
        ease: "none",
        onEnd: function() {
          activeIntro.classList.remove(activeIntroClass);
        }
      });
    }

    if (introContainer) {
      gsap.timeline().set(introContainer, {height: 'auto'})
      .from(introContainer, {
        height: 0,
        duration: 0.2,
        ease: 'power2.out',
        immediateRender: false,
        onEnd: function() {
          introContainer.classList.add(activeIntroClass);
        }
      }); 
    }
  }  

  const href = window.location.href;
  // Always remove page number when updating filters
  let url = href.includes('/page') ? href.split('/page')[0] : href.split('?filters')[0];
  // Check if need to clear sub filters
  // Parent filters will already be deselected due to being radio inputs
  if (filter.id === 'reset-filters') {
    subChecked.forEach(function(checkBox) {
      checkBox.checked = false;
    })
  } else {
    if (filter.dataset.level === "1") {
      subChecked.forEach(function(checkBox) {
        checkBox.checked = false;
      })
    }
    
    let updatedChecked = Array.from(document.querySelectorAll('.js-filter:checked'));
    updatedChecked = updatedChecked.filter(checked => checked.id !== 'reset-filters');
    const qString = updatedChecked.reduce((p, c, i) => {
      return p + c.value + `${i < updatedChecked.length - 1 && updatedChecked.length > 1 ? ',' : ''}`;
    }, '?filters=');
    url = url + qString;
  }
  ajax({url: url});
}

export default function() {
  document.addEventListener('click', function(e) {
    // Allow command-click and control-click to open new tab
    // http://henleyedition.com/allow-ctrl-command-click-using-event-preventdefault/
    if (e.metaKey || e.ctrlKey) {
      return;
    }
    const clicked = e.target;
    const loadMore = clicked.closest('#load-more');
    if (loadMore) {
      e.preventDefault();
      const url = decodeURIComponent(loadMore.href);
      ajax({
        url: url,
        append: true
      });
    } 
  });

  document.addEventListener('change', function(e) {
    const target = e.target;
    const filter = target.closest('.js-filter');
    if (filter) {
      e.stopPropagation();
      updateFilters(filter);
    }
  });
}
