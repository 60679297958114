import barba from '@barba/core';
import nav from './dropdown';

function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener('resize', function(event) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll function
      calcOffset();
  	});
  }, false);
}

function toggleSearch() {
  const rootEl = document.documentElement;
  rootEl.classList.toggle('search-is-active');

  if (rootEl.classList.contains('search-is-active')) {
    const searchInput = document.querySelector('#search-input')
    if (searchInput) {
      searchInput.focus();
    }
  }
}

function hideSearch() {
  const rootEl = document.documentElement;
  rootEl.classList.remove('search-is-active', 'search-is-searching');
  const searchForm = document.getElementById('search-form');
  searchForm.reset();
}

function ajaxSearch(e) {
  const rootEl = document.documentElement;
  rootEl.classList.add('search-is-searching');
  const searchForm = e.target;
  const url = searchForm.getAttribute('action');
  const formData = new FormData(searchForm);
  const queryString = new URLSearchParams(formData).toString();
  const fullUrl = `${url}?${queryString}`;
  barba.go(fullUrl);
  hideSearch();
}

function calcOffset() {
  // Get header height for top offset
  const header = document.getElementById('header');
  const height = header.offsetHeight;
  const searchMain = document.getElementById('search-main');
  searchMain.style.top = `${height}px`;
}

const search = {
  hideSearch: hideSearch,

  init() {
    const searchOpenButtons = Array.from(document.querySelectorAll('.js-search-open-button'));
    const searchForm = document.getElementById('search-form');
    calcOffset();
    initResizeListener();

    searchOpenButtons.forEach(button => {
      button.addEventListener('click', function(e) {
        toggleSearch();
        nav.hide();
      })
    });

    searchForm.addEventListener('submit', function(e) {
      e.preventDefault();
      ajaxSearch(e);
    });
  
    document.addEventListener('keydown', (e) => {
      const searchIsOpen = document.querySelector('.search-is-active')
      if (e.code === 'Escape' && searchIsOpen) {
        this.hideSearch();
      }
    });
  }
}

export default search;
