import gsap from 'gsap';

const body = document.body;
let prevScrollY = window.scrollY;

// TODO: change name to scrollListener
function initResizeListener() {
  // Setup a timer
  let timeout;
  // Listen for scroll events
  window.addEventListener('scroll', function(event) {
  	// If there's a timer, cancel it
  	if (timeout) {
  		window.cancelAnimationFrame(timeout);
  	}
    // Setup the new requestAnimationFrame()
  	timeout = window.requestAnimationFrame(function() {
      // Run our scroll function
      checkScroll();
  	});
  }, false);
}

function checkScroll() {
  const newScrollY = window.scrollY;

  if (newScrollY > prevScrollY || newScrollY === 0) {
    body.classList.remove('scrolling-up');
  } else {
    body.classList.add('scrolling-up');
  }
  prevScrollY = newScrollY;
}

export default function() {
  initResizeListener();
}
