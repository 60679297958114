import gsap from 'gsap';
import search from './search';
const navButton = document.getElementById('mobile-nav-button');
const body = document.body;
const activeClass = 'mobile-nav-is-active';
const nav = document.getElementById('mobile-nav');
const subButtons = document.querySelectorAll('.js-mobile-dropdown');

function hide() {
  body.classList.remove(activeClass);
  gsap.to(nav, {
    xPercent: -100,
    duration: 0.1,
    ease: 'none'
  })
}

function show() {
  body.classList.add(activeClass);
  gsap.to(nav, {
    xPercent: 100,
    duration: 0.1,
  })
}

function showSub(menuEl) {
  menuEl.classList.add('mobile-sub-is-active');
  const tl = gsap.timeline();
  tl.set(menuEl, {height: 'auto'})
  .from(menuEl, {
    height: 0,
    duration: 0.2,
    ease: 'power2.out',
    immediateRender: false
  });
}

function hideSub(menu) {
  const activeSub = menu || document.querySelector('.mobile-sub-is-active');
  if (!activeSub) {
    return;
  }
  activeSub.classList.remove('mobile-sub-is-active');
  gsap.to(activeSub, {
    height: 0,
    duration: 0.1,
    ease: 'none'
  });
}

function handle(e) {
  const button = e.currentTarget;
  const id = button.dataset.menu;
  const menuEl = document.getElementById(id);
  const activeSub = document.querySelector('.mobile-sub-is-active');
  if (activeSub) {
    if (activeSub === menuEl) {
      hideSub(activeSub);
    } else {
      hideSub(activeSub);
      showSub(menuEl);
    }
  } else {
    showSub(menuEl);
  }
}

function toggle() {
  if (body.classList.contains(activeClass)) {
    search.hideSearch();
    hideSub();
    hide();
  } else {
    show();
  }
}

const mobile = {
  init: function() {
    navButton.addEventListener('click', toggle);
    subButtons.forEach(button => {
      button.addEventListener('click', handle);
    });
  },
  hide: hide,
  hideSub: hideSub,
}

export default mobile;